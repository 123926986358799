import { type ChangeEvent, type ReactNode, useRef, useState, useEffect } from "react";
import { FormControl, FormLabel, Box } from "@mui/material";
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/material/styles';

type TextareaWithActionsProps = {
  value: string,
  onChange: (change: ChangeEvent<HTMLTextAreaElement>) => void,
  required?: boolean,
  label?: string,
  errors?: string[],
  disabled?: boolean,
  actions?: ReactNode,
  actionsOnOverflow?: ReactNode,
}

export const TextareaWithActions: React.FC<TextareaWithActionsProps> = ({
  value,
  onChange,
  required,
  label,
  errors,
  disabled,
  actions,
  actionsOnOverflow,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (!textareaRef.current) {
      return;
    }

    const { scrollHeight, clientHeight } = textareaRef.current;
    const overflowState = scrollHeight > clientHeight;

    overflowState !== isOverflowing && setIsOverflowing(overflowState);
  };

  useEffect(() => {
    checkOverflow();

    const handleResize = () => checkOverflow();
    window.removeEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    checkOverflow();
  }

  const hasAnyActions = actions || actionsOnOverflow;
  const hasErrors = Boolean(errors?.length);

  return (
    <FormControl
      fullWidth
      required={required}
      disabled={disabled}
      sx={{ margin: 1 }}
      error={hasErrors}
    >
      <LabelContainerWithActions as={Box}>
        <LabelText>{label}</LabelText>

        {hasAnyActions && (
          <ActionsContainer>
            {isOverflowing && !hasErrors && actionsOnOverflow}
            {actions}
          </ActionsContainer>
        )}
      </LabelContainerWithActions>

      <StyledTextarea
        disabled={disabled}
        value={value}
        onChange={handleChange}
        minRows={5}
        maxRows={5}
        ref={textareaRef}
        className={hasErrors ? 'error' : ''}
      />

      {hasErrors && (
        <ErrorBox>
          {errors?.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </ErrorBox>
      )}
    </FormControl>
  )
};

const LabelContainerWithActions = styled(FormLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
`;

const LabelText = styled('span')`
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #333;
`;

const ActionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 24px;
`

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  word-spacing: 2px;
  color: inherit;
  resize: none;
  padding: 8px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
  transition: border-color .2s ease-in-out;

  &[disabled] {
    color: rgba(0, 0, 0, 0.38);
  }

  &.error {
    border: 1px solid red;
    backgroundColor: #ffebee;
  }
`;

const ErrorBox = styled(Box)`
  color: red;
  fontSize: .875rem;
  marginTop: 4px;
`;
